.website-main-container{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 0px;
}

.heading-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
}


.heading-info{
    display: flex;
    flex-direction: column;
    width: 508px;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
}

.heading-info h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #12141D;
    margin-bottom: -1px;
}

.Typewriter__wrapper{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #6AA0A8;
    opacity: 0.8;
    margin-bottom: 20px;
}

.heading-info-btn-chat{
    margin-top: 40px;
}

.heading-info-btn-chat .button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    width: 220px;
    height: 56px;
    background: #6FBDC9;
    border-radius: 100px;
    border: none;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-out;
  
}

.heading-info-btn-chat .button:hover {
    background-color: #FFFFFF;
    color: #6FBDC9;
    border: 1px solid #6AA0A8;
    transition: 0.2s ease-in;
}

.heading-customers-info{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}

.heading-customer-images{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin-right: 4px;
}

.heading-customer-images img{
    width: 135.56px;
    height: 60.52px;
}

.heading-customers{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px;
    margin-right: 18px;
}

.heading-customers h1{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #12141D;
}

.heading-customers h2{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #12141D;
    opacity: 0.6;
}


.heading-customers-ratings{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px;
}

.heading-customers-ratings h1{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: -1px;
    color: #12141D;
}

.heading-customers-ratings img{
    width: 77.01px;
    height: 12.46px;
}

.heading-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.heading-image img{
    width: 560px;
    height: 533.56px;
}

.ready{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #6FBDC9;
    border-radius: 32px;
    width: 80%;
    align-self: center;
    margin-top: -220px;
    padding-top: 40px;
    padding-bottom: 20px;
}

.ready-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: 40%;
}

.ready-container h1{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 21px;
}

.ready-container h4{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.7;
    margin-bottom: 11px;

}

.ready-container button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 10px;
        width: 206px;
        height: 60px;
        background: #FFFFFF;
        border-radius: 10100px;
        border: none;
        cursor: pointer;
    }

    .Typewriter__cursor{
        display: none;
    }

    @media screen and (max-width:940px) {
        
        .heading-info{
            width: 508px;
        }

        .ready{
            margin-top: -180px;
        }

        .ready-container{
            padding: 20px;
        }

        .ready-container h1{
            font-size: 34px;
            line-height: 42px;
            margin-bottom: 16px;
        }
        
        .ready-container h4{
            font-size: 16px;
            line-height: 26px;        
        }

                
        .ready-container button{
            padding: 14px;
            width: 200px;
            height: 56px;
        }


    }


    @media screen and (max-width:890px) {
        
        .heading-info{
            display: flex;
            flex-direction: column;
            width: 508px;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 60px;
            padding-top: 80px;
            margin-right: 10px;
        }

        .heading-info h3{
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
            color: #12141D;
            margin-bottom: -1px;
        }

        .Typewriter__wrapper{
            font-weight: 700;
            font-size: 12px;
            line-height: 32px;
            color: #6AA0A8;
            opacity: 0.8;
            padding:5px;
            margin-bottom: 20px;
        }

        .heading-info-btn-chat{
            margin-top: 40px;
        }

        .heading-info-btn-chat .button{
            padding: 14px;
            font-weight: 500;
            font-size: 15px;
            line-height: 23px;
            color: #FFFFFF;
            cursor: pointer;
        }

        .heading-image{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .heading-image img{
            width: 370px;
            height: 333.56px;
        }

    }




    @media screen and (max-width:868px) {
        
        .heading-container{
            width: 700px;
        }

        .heading-info{
            padding: 10px;
            padding-top: 100px;
            width: 250px;
            margin-right: 5px;
        }

        .heading-info-btn-chat{
            margin-top: 20px;
        }

        .Typewriter__wrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-weight: 700;
            font-size: 10px;
            line-height: 32px;
            color: #6AA0A8;
            opacity: 0.8;
            margin-bottom: 4px;
            width: 400px;

        }

        .heading-image{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .heading-image img{
            width: 400px;
            height: 330.56px;
        }

    }


@media screen and (max-width:670px) {

    
    .ready{
        margin-top: -180px;
    }

    .ready-container{
        padding: 20px;
    }

    .ready-container h1{
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 16px;
    }
    
    .ready-container h4{
        font-size: 12px;
        line-height: 22px;        
    }

            
    .ready-container button{
        padding: 14px;
        width: 200px;
        height: 50px;
    }

    
}


    @media screen and (max-width:600px) {
        
        .heading-container{
            width: 500px;
            flex-direction: column;
            padding-top: 0;
            padding-left: 20px;
            padding-right: 20px;
        }

        .heading-info{
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px;
            width: 70%;
            margin-bottom: 10px;
        }

        .heading-info-btn-chat{
            margin-top: 20px;
        }

        .heading-customer-images{
            margin-top: -40px;
        }

        .Typewriter__wrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-weight: 700;
            font-size: 10px;
            line-height: 32px;
            color: #6AA0A8;
            opacity: 0.8;
            margin-bottom: 4px;
            width: 400px;

        }

        .heading-image{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .heading-image img{
            width: 520px;
            height: 420.56px;
        }


    }




@media screen and (max-width:530px) {

    .heading-container{
        width: 400px;
        flex-direction: column;
        padding-top: 0;
    }

    .heading-image img{
        width: 440px;
        height: 360.56px;
    }


    .ready{
        margin-top: -180px;
    }

    .ready-container{
        padding: 10px;
    }

    .ready-container h1{
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 14px;
    }
    
    .ready-container h4{
        font-size: 12px;
        line-height: 20px;        
    }

            
    .ready-container button{
        padding: 14px;
        width: 180px;
        height: 48px;
    }

    .Typewriter__wrapper{
        width: 360px;
    }

}


@media screen and (max-width:477px) {
   
    .heading-container{
        width: 380px;
        flex-direction: column;
        padding-top: 0;
        padding-left: 3px;
        padding-right: 3px;
    }

    .heading-image img{
        width: 340px;
        height: 300.56px;
    }

    
    .ready{
        margin-top: -180px;
    }

    .ready-container{
        padding: 10px;
    }

    .ready-container h1{
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 14px;
    }
    
    .ready-container h4{
        font-size: 12px;
        line-height: 20px;        
    }

            
    .ready-container button{
        padding: 14px;
        width: 180px;
        height: 48px;
    }

    .Typewriter__wrapper{
        width: 300px;
    }

}


@media screen and (max-width:377px) {

    .heading-container{
        width: 340px;
    }

    .heading-image img{
        width: 340px;
        height: 300.56px;
    }


    .ready{
        margin-top: -180px;
    }

    .ready-container{
        padding: 10px;
    }

    .ready-container h1{
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 14px;
    }
    
    .ready-container h4{
        font-size: 10px;
        line-height: 15px;        
    }

            
    .ready-container button{
        padding: 14px;
        width: 180px;
        height: 48px;
    }

}



@media screen and (max-width:357px) {

    .heading-container{
        width: 300px;
    }

    .heading-image img{
        width: 290px;
        height: 240.56px;
    }

}