.welcome-container{
    display: flex;
    background-image: url(../../Assets/Background-1.png) ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.welcome{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;
}

.welcome .welcomeImg{
    width: 87px;
    height: 81px;
    margin-bottom: 30px;
}

.logo{
    width: 153px;
    height: 33px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.welcome h1{
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: var(--primary);
}

.welcome h2{
    font-family: 'Helvetica';
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.welcome-actions{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.btn{
    width: 80%;
    padding: 3px;
    color: #000000;
    font-size: medium;
    border-radius: 10px;
    height: 46px;
    margin-bottom: 5px;

}

.btn-login{
    background-color: var(--primary);
    color: white;
}

@media screen and (max-width: 770px) {

    .welcome{
        width: 40%;
      
    }

    .welcome h1{
       
        font-size: 17px;
        margin-bottom: -5px;

    }
    
    .welcome h2{
        font-size: 15px;
    }
    
    .logo{
        width: 130px;
        height: 30px;
        margin-top: 10px;
        margin-bottom: 15px;
    }


}



@media screen and (max-width: 550px) {

    .welcome{
        width: 50%;
      
    }

    .welcome h1{
       
        font-size: 16px;
        margin-bottom: -5px;

    }
    
    .welcome h2{
        font-size: 14px;
    }

    
    .logo{
        width: 130px;
        height: 26px;
        margin-top: 20px;
        margin-bottom: 10px;
    }


    .btn{
        height: 26px;
        margin-bottom: 5px;
    }
    
}


@media screen and (max-width: 450px) {

    .welcome{
        width: 70%;
      
    }

    .welcome h1{
       
        font-size: 14px;
        margin-bottom: -10px;

    }
    
    .welcome h2{
        font-size: 12px;
    }

    .logo{
        width: 120px;
        height: 25px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    
    .welcome-actions{
        padding: 6px;
    }

    .btn{
        width: 90%;
    }
}