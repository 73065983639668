.container {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
}

.mobile-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    padding-bottom: 50%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.mobile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 40%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    color: #fff;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
}

.mobile-content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    color: #fff;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
}
