.App {
  text-align: center;
  overflow: hidden;
  /* background-color: black; */
}
.top {
  background-image: url("https://s3-alpha-sig.figma.com/img/fa79/eb47/c5de70e31559c9a6962f8b8d452d476b?Expires=1672617600&Signature=kDBwPzP2m0ouhQXQSDZqiJXVZ4dRRd3bwFLK-DhMoUteWVfEfqMsEUcDGmm3n2XAoAwdjxsRht7G2Brn-Vcq1a573wRxZcG28cy-x1fuLRc1dH5lKsku5gFeMgc1NmFe2UUIryD~yrmt6h1anXjP7rVQiOD7XuUJVSmGwA7-yMvQf29XarEJzjlZ-0DnkJpJaHOQAJVN90zb2NsXjbnm3L84NH0iMk-XymxrtXlHB1HnT7CQwC0US4XX7-JZfPrO-AePNZYzN693y2yYKTwm0ppZ9NKoGWxoDoag2xfquEkwnwWNFcmqpRiMLKIoa631-bAd6j-1ETcmm1t91zw5YA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
  background-position: center;
  height: 2250px;
}
#companylogo {
  background-image: url("https://s3-alpha-sig.figma.com/img/c878/c42f/34cc79a861a2584855c09104fbf8ee9a?Expires=1665360000&Signature=USlrCDwWut0oRfdRpK12-5BbAWcJvIV7uFEgHrIbKSIFVCd2hi53BZXTxeNrc-U4vINHx9UjncW4p4oFPJSDRtK~Hini7531zPQ6vXu7SW-NweMG7ieXPqzbAhyZeaqo~rGTWyQO5ON2~fu8uVDl6aRdXX498NJq-spMtkSJeVo94Nwc8x-bFCY~-i5TYGaEYlDQJeHxbZCLc-qceMQRZ16YpVWtKkbWWXqVqp~9XOPgmFEStX4SP~GE~hiOvhmfBafWOFIKbc7jvAPHQNh4lJit62ObG51LyxiU7Sxcjdn4Pbt7MpoaiMMUpckZg8Ex~4XG6MCROngfPPHwp8PZgQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA");
  background-position: top left 10px;
}

.splash {
  background-image: url("https://s3-alpha-sig.figma.com/img/c878/c42f/34cc79a861a2584855c09104fbf8ee9a?Expires=1673827200&Signature=EH4a67TmdKLo4dBG8ADO683m~H0fT8FBRI4uX1Aayu28S0BdnjBCsokYgkMmDi4HRaOH-i6ked6zZ5iwLrvSZVs6lUGOpOO09xHtDLRvfutS7PerHaLvIevllSOeodGYJ09oZ3KXIo2ro767BbAWVJe9f9-LaCt8YNqBMbpWzXQbql8dMLL4sNIQ5iNs0d2acrgm7fAYcbRusXevCtC3mrxJ3nxJmnrhwCd0B05-PCayvxThvOeAeE~iR0gHLeFNJcNQKCkVSOl1BzFhJ~nhBJSy7IJXihx3WzhW8aZvsJwnmm83rAsAWayMwcpYsJDsiUXMC6DvX~hcI~H6tzzAWA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
  background-position: center, left top;
  background-repeat: no-repeat, repeat;
  height: 2285px;
}

#welcome {
  background-image: url("https://s3-alpha-sig.figma.com/img/fa79/eb47/c5de70e31559c9a6962f8b8d452d476b?Expires=1673827200&Signature=BH~yOHLJahYrBNZ78Yo7jiZOvyX8~blS-PzvUeWrQmnldHagX~8epP~C4xi78QbmdQYR6AwZ1QZjpDvWhc-0xrFI~KTSqSbEjGN9PxEJKzEG0I3SO5yBHeqC4SDy1-mzjjSK1lR8PXH1B56XICpKmAeNUz4HF-WYGP2d-ul2AafUAXcpYb8bL37zNvJbSWywh3n7oeIJiY5uUkbU0T8Fax1O9MISSaDeIVBxLIbtOYOM~UYMiTkHtFOQ8mS5ikmSIqFnRudmnDRuJ3RI2FstiFOAfkhlaDYte4vRoRzjhQIkvizy2p84c4p48wSUrXrHyb7Vq4oy0r6qHm0pD-S~Wg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"),
    url("https://s3-alpha-sig.figma.com/img/c878/c42f/34cc79a861a2584855c09104fbf8ee9a?Expires=1675036800&Signature=ei76~1V9dujFCHs2~2hsEIR7HeLt8gro3uUdbkMomelLv0d3zrq~rPgZhPwsTQOJAGvGGHMlnPIvbdvP8ViwidL8jh38JfZKCImpe9aJwp2w8Pca3s0FgWXPMWx2TsV1W3crnU1~dN034xWRu6jqNKUfBCGwTBgeaN4eKOabCYFO4RTPFFmdP1yMoFJbFv~SzmmphS4fOCipQT~EGxPqrZYKNAasOrX2DFyqU1FGLuCp6WWqGpNbrqEBD2v0so28Y-wg6fVb7R03wZ5kZ-MfiLD~hgI0zfyz9IYBdJum06Extby3WQIngTafPz4s7ieVXLKMbroaMCkSI6jNfUwk8Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
  background-size: 300px, auto;
  background-position: 50% 6%;
  background-repeat: no-repeat, repeat;
  padding: 15px;
  /* margin-top: 0px, 40px; */
  height: 1987px;
  margin-top: 8%, -90px;
}
#homepage2 {
  background-image: url("https://s3-alpha-sig.figma.com/img/fa79/eb47/c5de70e31559c9a6962f8b8d452d476b?Expires=1673827200&Signature=BH~yOHLJahYrBNZ78Yo7jiZOvyX8~blS-PzvUeWrQmnldHagX~8epP~C4xi78QbmdQYR6AwZ1QZjpDvWhc-0xrFI~KTSqSbEjGN9PxEJKzEG0I3SO5yBHeqC4SDy1-mzjjSK1lR8PXH1B56XICpKmAeNUz4HF-WYGP2d-ul2AafUAXcpYb8bL37zNvJbSWywh3n7oeIJiY5uUkbU0T8Fax1O9MISSaDeIVBxLIbtOYOM~UYMiTkHtFOQ8mS5ikmSIqFnRudmnDRuJ3RI2FstiFOAfkhlaDYte4vRoRzjhQIkvizy2p84c4p48wSUrXrHyb7Vq4oy0r6qHm0pD-S~Wg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"),
    url("https://s3-alpha-sig.figma.com/img/c878/c42f/34cc79a861a2584855c09104fbf8ee9a?Expires=1675036800&Signature=ei76~1V9dujFCHs2~2hsEIR7HeLt8gro3uUdbkMomelLv0d3zrq~rPgZhPwsTQOJAGvGGHMlnPIvbdvP8ViwidL8jh38JfZKCImpe9aJwp2w8Pca3s0FgWXPMWx2TsV1W3crnU1~dN034xWRu6jqNKUfBCGwTBgeaN4eKOabCYFO4RTPFFmdP1yMoFJbFv~SzmmphS4fOCipQT~EGxPqrZYKNAasOrX2DFyqU1FGLuCp6WWqGpNbrqEBD2v0so28Y-wg6fVb7R03wZ5kZ-MfiLD~hgI0zfyz9IYBdJum06Extby3WQIngTafPz4s7ieVXLKMbroaMCkSI6jNfUwk8Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
  background-size: 300px, auto;
  background-position: 50% 6%;
  background-repeat: no-repeat, repeat;
  padding: 15px;
  /* margin-top: 0px, 40px; */
  height: 1987px;
  margin-top: 8%, -90px;
}

#rcorners2 {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 740px;
  height: 818px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
}
#rcorners3 {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 740px;
  height: 1200px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
}
#rcorners4 {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #f8f9fb;
  background: #f8f9fb;
  padding: 20px;
  width: 1340px;
  height: 1000px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
}
#rcorners5 {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: white;
  padding: 20px;
  width: 590px;
  height: 618px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
  margin-left: -350px;
}
#rcorners6 {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: white;
  padding: 20px;
  width: 590px;
  height: 618px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
  margin-left: 350px;
}

#motto {
  font-size: 30px;
  font-family: Helvetica;
  font-weight: normal;
  /* line-height: 8.26px; */
  align-items: center;
  vertical-align: top;
  color: #000000;
  margin-bottom: -20px;
}
#login {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  color: #ffffff;
  border: 2px solid #52b1bb;
  background: #52b1bb;
  width: 630px;
  height: 76px;
  margin-bottom: 30px;
  /* margin-left: 1%; */
}
#signuplogin {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  color: #ffffff;
  border: 2px solid #52b1bb;
  background: #52b1bb;
  width: 630px;
  height: 76px;
  margin-bottom: 30px;
  margin-left: 6.5%;
}

#create {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  color: #52b1bb;
  border: 2px solid #52b1bb;
  background: #ffffff;
  width: 630px;
  height: 76px;
  margin-bottom: 30px;
}
#parainput {
  height: 600px;
  width: 85%;
  margin: 12;
  border-width: 1;
  padding: 10;
  border-color: #000000;
  margin-bottom: 4%;
  /* position: absolute; */
  /* left:auto; */
  margin-top: 200px;
  margin-left: -7%;
  background-color: #d2e1e2;
  font-size: larger;
  border-radius: 10px;
}

#community {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin-bottom: -500px;
}
#promptyprompt {
  color: #52b1bb;
  font-size: 56px;
  position: absolute;
  margin-left: 4%;
  /* left: auto; */
  /* margin-left: 2%; */
}
#TAGmargins {
  /* border-radius: 4px; */
  width: 80px;
  height: 80px;
  margin-right: 10px;
  fill: #52b1bb;
}
#promptypromptTAG {
  color: #52b1bb;
  font-size: 46px;
  /* position: absolute; */
  border-color: #000;
  width: 600px;
  height: 70px;
  border-width: 1;
  border-radius: 10px;
  background-color: #d2e1e2;
  display: inline-block;
  margin-right: 8px;

  /* margin-left: 2%; */
}
#toTheMoon {
  background-color: #399faa;
  color: black;
}
#promptypromptPROMPT {
  color: #52b1bb;
  font-size: 46px;
  position: absolute;

  /* margin-left: 2%; */
}

#continue {
  font-family: Arial, Helvetica, sans-serif;
  font-style: bold;
  border-radius: 10px;
  align-items: center;
  color: #000000;
  border: 2px solid #000000;
  background: #ffffff;
  width: 630px;
  height: 76px;
  margin-bottom: 15px;
}
#create_account {
  font-size: 30px;
  font-family: Helvetica;
  font-weight: lighter;
  /* line-height: 8.26px; */
  /* align-items: center; */
  vertical-align: baseline;
  color: #000000;
  display: center;
  margin-right: 220px;
}
.gg-arrow-left {
  /* width: 82px;
  height: 82px; */
  color: #52b1bb;
  margin-right: 165px;
  box-shadow: none !important;
  /* size: 15%; */
}

/* .gg-arrow-left::after,
 .gg-arrow-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  color: #52B1BB;
 }

 .gg-arrow-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
  color: #52B1BB;
 }

 .gg-arrow-left::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
  color: #52B1BB;
 } */

#heading {
  justify-content: space-around;
}

#span {
  display: block;
}

input[type="password"] {
  font-size: 44px;
}
input[type="text2"] {
  font-size: 24px;
}

#signup-buttons {
  width: 630px;
  height: 76px;
  margin-bottom: 30px;
  border-radius: 10px;
  border-color: #d2e1e2;
  background-color: #f3f9fb;
}
#bio-box {
  width: 630px;
  height: 176px;
  margin-bottom: 30px;
  border-radius: 10px;
  border-color: #d2e1e2;
  background-color: #f3f9fb;
}

#verification {
  font-family: Arial, Helvetica, sans-serif;
}
#boxes {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  fill: #52b1bb;
}

#details {
  font-size: medium;
  font-weight: 30;
  color: gray;
  margin-bottom: 20px;
}

#confirm {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: large;
  border-radius: 10px;
  align-items: center;
  color: #ffffff;
  border: 2px solid #52b1bb;
  background: #52b1bb;
  width: 530px;
  height: 76px;
  margin-bottom: 30px;
  font-weight: bold;
}
#confirm2 {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: large;
  border-radius: 10px;
  align-items: center;
  color: #ffffff;
  border: 2px solid #52b1bb;
  background: #52b1bb;
  width: 530px;
  height: 76px;
  margin-bottom: 30px;
  font-weight: bold;
  left: 0%;
  position: absolute;
  margin-left: 4%;
}
.MyCustomButton:hover {
  background-color: inherit !important;
}

#resend {
  text-decoration: underline;
  font-weight: bold;
  color: black;
  font-size: medium;
}

#slct {
  width: 530px;
  height: 76px;
  margin-bottom: 0px;
}

#nolayer {
  margin-top: 60px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f8f8f8;
  padding: 20px;
  width: 490px;
  height: 340px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}
#nolayer2 {
  margin-top: 60px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f8f8f8;
  padding: 20px;
  width: 750px;
  height: 540px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin-left: -20%;
}
#av {
  height: 12%;
  width: 12%;
}
#stratos {
  font-size: 31px;
  font-weight: lighter;
  color: gray;
}
#hate {
  font-weight: bold;
}

#everyone {
  font-size: xx-large;
  color: black;
}
#stratosterms {
  color: #52b1bb;
  font-weight: bold;
}

#customcb3 {
  height: 90px;
  width: 90px;
}

#finish {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: large;
  border-radius: 10px;
  align-items: center;
  color: #ffffff;
  border: 2px solid #52b1bb;
  background: #52b1bb;
  width: 430px;
  height: 76px;
  margin-bottom: 30px;
}

#seperateIcon {
  left: 0;
  margin-right: 133px;
}

#contentSwitch {
  margin-right: 160px;
}

#dropdown {
  color: black;
}
#dropdown2 {
  color: black;
  width: 29%;
  margin-left: 25%;
  margin-top: -21%;
}

#profimg {
  border-radius: 50%;
}
#twoModal {
  justify-content: space-around;
}
#heading2 {
  justify-content: space-around;
  height: 980px;
}

#termsConditions {
  color: gray;
  font-weight: lighter;
  font-size: small;
  height: 580px;
  margin-top: -130px;
}

#personalDetailsHead {
  color: gray;
  font-size: medium;
  font-weight: lighter;
  margin-top: -18%;
}
#usrdeets {
  color: #000000;
}

#personalDetails {
  font-size: small;
  font-weight: lighter;
  margin-top: -18%;
}
#conf {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;
  color: #ffffff;
  border: 2px solid #52b1bb;
  background: #52b1bb;
  width: 630px;
  height: 76px;
}
#likeCount {
  margin-left: 31%;
  color: #515151;
}
#commentCount {
  margin-left: 31%;
  color: #515151;
}
#shareCount {
  margin-left: 31%;
  color: #515151;
}

#comguidelines {
  color: gray;
  font-weight: lighter;
  font-size: small;
  height: 580px;
  margin-top: 100px;
}

#rcorners_comguide {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 815px;
  height: 1000px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  line-height: 1.6;
  /* min-height: 100vh; */
}
#create_account_com {
  font-size: 30px;
  font-family: Helvetica;
  font-weight: lighter;
  color: #000000;
}
#draw {
  position: relative;
  /* background-color: #52B1BB; */
  width: 240px;
  height: 2440px;
  /* flex-shrink: 0;  */
  /* box-sizing: border-box; */

  /* sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
      },

    }} */
}
#join {
  color: black;
}
#draw2 {
  background-color: #52b1bb;
  width: 440px;
  height: 1000px;
}
#draw3 {
  background-color: #52b1bb;
  width: 440px;
  height: 2440px;
  position: static;
  /* margin-top: 100px; */
}
#everythingelse {
  margin-bottom: 20px;
  margin-left: 5%;
}

#rcorners10 {
  margin-top: 400px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 1600px;
  height: 100px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
}

#rcorners101 {
  /* margin-top: 100px; */
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 2000px;
  height: 180px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  /* margin: 0; */
  /* position: absolute; */
  top: 50%;
  margin-left: 50%;
  /* margin-left: 5%; */
  /* margin-right: -50%; */
  margin-top: -20%;
  transform: translate(-50%, -50%);
  /* overflow-y: scroll; */
  /* min-height: 100vh; */
}
#rcornersHC {
  /* margin-top: 100px; */
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 2000px;
  height: 180px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  /* margin: 0; */
  /* position: absolute; */
  top: 50%;
  /* margin-left: 50%; */
  /* margin-left: -36%; */
  /* margin-right: -50%; */
  margin-top: -20%;
  transform: translate(-50%, -50%);
  /* overflow-y: scroll; */
  /* min-height: 100vh; */
}
#profileBio {
  font-size: 38px;
  color: #2a2e33;
  overflow-wrap: normal;
}
#rcorners1012 {
  /* margin-top: 400px; */
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 3900px;
  height: 3000px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  /* margin: 0; */
  position: absolute;
  top: 80%;
  left: 50%;
  /* overflow-y: scroll; */
  align-items: center;

  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
}
#jobdetaillinks {
  display: flex;
}
#company {
  color: #000000;
  font-weight: lighter;
  margin-left: 8%;
  /* margin-bottom: 95%; */
}
#roletypes {
  display: flex;
  margin-left: 18%;
  /* margin-bottom: 2%; */
  /* color: #FFFFFF; */
}
#prof2 {
  flex-direction: row;
}
#profModal {
  /* margin-top: 400px; */
  border-radius: 30px;
  border: 2px solid #ffffff;
  background: #ffffff;
  padding: 20px;
  width: 2480px;
  height: 2100px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  text-align: start;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  color: #52b1bb;
  margin-top: 10%;
  position: absolute;
  top: 50%;
  left: 56%;
  overflow-y: scroll;

  transform: translate(-50%, -50%);
  /* min-height: 100vh; */
}
#rcornersprof {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: white;
  padding: 20px;
  width: 1100px;
  height: 1318px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  margin-left: 14%;
  position: relative;
  margin-left: 110px;
  /* margin-bottom: 24%; */
  /* margin-right: auto; */
  /* top: 45%;
    left: 50%; */
  transform: translate(10%, 10%);
  /* min-height: 100vh; */
}
#job_card {
  width: 900px;
  height: 290px;
  background-color: #ffffff;
  outline: 1px solid; /* Set the outline width and style */
  outline-color: #dddddd;
  border-radius: 20px;
  font-size: 15px;
  text-align: start;
  margin-bottom: 4%;
}
#roletypes1 {
  width: 250px;
  height: 70px;
  border-radius: 30px;
  background-color: #52b1bb;
  text-align: center;
  color: #ffffff;
  margin-right: 4%;
}
#website {
  display: flex;
  color: #000000;
}
#CompanyLogoJD {
  width: 220px;
  height: 220px;
  border-radius: 40px;
  border-style: solid;
  border-color: #e0e0e0;
  /* background-color: #000; */
  margin-top: 300px;
  align-items: center;
  margin-left: 36%;
}
#iconJD {
  margin-right: 5%;
}
#facebook {
  display: flex;
  color: #000000;
}
#linkedin {
  display: flex;
  color: #000000;
}
#desc {
  display: block;
}
#verticaline {
  width: 1px;
  height: 50px;
  float: left;
  /* size: 500; */
  background-color: #dfdfdf;
  border: none;
}
#benefitchecks {
  display: flex;
  margin-top: 3%;
}
#descJD {
  margin-bottom: 3%;
}
#checkicon {
  color: #18b60b;
  margin-right: 3%;
}
#companydescription {
  font-size: 82%;
  color: #4f4f4f;
  font-weight: lighter;
}
#jobdescription {
  color: #4f4f4f;
  font-size: 82%;
  font-weight: lighter;
}
#companyrequirements {
  font-size: 82%;
  color: #4f4f4f;
  font-weight: lighter;
}
#salary {
  color: #515151;
}
#companyjobdeets {
  text-align: start;
  color: #000000;
}
#belowsalary {
  background-color: #dfdfdf;
  margin-left: -107%;
  /* margin-right: 85%; */
  /* align-items: start; */
  width: 380%;
  height: 1px;
  /* align-items:flex-start; */
  /* margin-right: 25%; */
  /* margin-right: 120px; */
  /* margin-left: 20px; */
  border: none;
}

#rcornersprofPROF {
  border-radius: 20px;
  border: 2px solid #ffffff;
  background-color: green;
  /* margin-left: 40%; */
  /* padding: 20px;  */
  width: 700px;
  height: 1920px;
  /* display: inline-grid; */
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  margin-left: 14%;
  /* margin-bottom: 24%; */
  /* transform: translate(10%, 10%); */
  /* min-height: 100vh; */
  margin-bottom: 25%;
}
#rcornersprof2 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: white;
  padding: 20px;
  width: 2600px;
  height: 1318px;
  /* display: inline-grid; */
  justify-content: start;
  align-items: flex-start;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: 155px;
  /* margin-right: auto; */
  /* top: 45%;
    left: 50%; */
  transform: translate(10%, 10%);
  /* min-height: 100vh; */
  /* left: auto; */
  display: block;
  background-color: #f3f9fb;
  /* background-color: #E5E5E5; */
}
#rcornersprof3 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: white;
  padding: 20px;
  width: 2600px;
  height: 1618px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: 55px;
  /* margin-right: 155px; */
  /* margin-right: auto; */
  /* top: 45%;
    left: 50%; */
  transform: translate(10%, 10%);
  /* min-height: 100vh; */
}
#rcornersprof4 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 400px;
  height: 600px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: 155px;
  transform: translate(10%, 10%);
}
#rcornersprof5 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 580px;
  height: 100px;
  /* top: 19px; */
  left: 2px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  /* vertical-align: top; */
  text-align: left;
  /* text-align: center; */
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: -20px;
  transform: translate(10%, 10%);
  margin-top: -11%;
  margin-bottom: 1%;
}
#rcornersprof5::placeholder {
  font-weight: bold;
  font-size: x-large;
  opacity: 0.5;
  color: #2a2e33;
  height: auto;
}
#rcornersprof6::placeholder {
  font-weight: bold;
  font-size: x-large;
  opacity: 0.5;
  color: #2a2e33;
  height: auto;
}
#rcornersprof555::placeholder {
  font-weight: bold;
  font-size: x-large;
  opacity: 0.5;
  color: #2a2e33;
  height: auto;
}
#rcornersprof7::placeholder {
  font-weight: bold;
  font-size: x-large;
  opacity: 0.5;
  color: #2a2e33;
  height: auto;
}
#rcornersprof56::placeholder {
  font-weight: bold;
  font-size: x-large;
  opacity: 0.5;
  color: #2a2e33;
  height: auto;
}
#rcornersprof55::placeholder {
  font-weight: bold;
  font-size: x-large;
  opacity: 0.5;
  color: #2a2e33;
  height: auto;
}

#rcornersprof55 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 580px;
  height: 100px;
  /* top: 19px; */
  left: 580px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: 155px;
  transform: translate(10%, 10%);
  margin-top: -11%;
  margin-bottom: 1%;
}
#rcornersprof56 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 580px;
  height: 100px;
  /* top: 19px; */
  left: 580px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: 155px;
  transform: translate(10%, 10%);
  /* margin-top: -11%; */
  /* margin-bottom: 9%; */
}
#rcornersprof6 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 580px;
  height: 100px;
  /* top: 19px; */
  left: 2px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: -20px;
  transform: translate(10%, 10%);
  /* margin-top: -11%; */
  /* margin-bottom: 9%; */
}
#rcornersprof7 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 580px;
  height: 300px;
  /* top: 19px; */
  left: 2px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: -20px;
  transform: translate(10%, 10%);
  /* margin-top: -11%; */
  margin-bottom: 3%;
}
#rcornersprof555 {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: #f3f9fb;
  padding: 20px;
  width: 580px;
  height: 100px;
  /* top: 19px; */
  left: 580px;
  /* display: inline-grid; */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 38px;
  /* color:#52B1BB; */
  /* margin: 0; */
  position: relative;
  margin-left: 155px;
  transform: translate(10%, 10%);
  margin-top: 15.8%;
  margin-bottom: 1%;
}
/* #sepicons{
    display: flex;
    justify-content: space-between;
  } */
#commentcomment {
  color: #707070;
  font-size: 15px;
}
#commentName {
  margin-left: 6%;
  color: #000000;
  font-size: 30px;
}
#singlecomment {
  /* margin-right: 200px; */
  background-color: #f3f9fb;
  width: 650px;
  height: 200px;
  border-radius: 20px;
}
#toggle {
  transform: scale(1.8);
}
#wrapper {
  display: flex;
}
#em {
  flex: 1;
  /* position: absolute;
    left: 35%; */
  /* position: absolute; */
  color: #52b1bb;
  font-weight: lighter;
  /* margin-left: -5%; */
}
#em2 {
  /* flex:-0.2; */
}
#inp {
  display: block;
  margin-left: -25%;
  margin-top: -13%;
  text-align: center;
}
#inp2 {
  display: block;
  margin-left: -25%;
  margin-top: -29.8%;
}
#hrhrhr {
  margin-left: 42.5%;
  margin-right: 5%;
  background-color: #dcdcdc;
  height: 1px;
  /* margin-left: 20px; */
  border: none;
  margin-top: 5%;
}
#cancelcancel {
  color: #7e7e7e;
  background: #ffffff;
  /* width: 161px;
    height: 46px; */
  font-family: Arial, Helvetica, sans-serif;
  font-style: bold;
  border-radius: 10px;
  align-items: center;
  /* color:#000000; */
  border: 2px solid #7e7e7e;
  /* background: #FFFFFF; */
  width: 330px;
  height: 76px;
  margin-bottom: 15px;
  margin-right: 2%;
  margin-left: 7.5%;
}
#shifty {
  margin-left: -29%;
}
#savesave {
  color: #ffffff;
  background: #52b1bb;
  /* width: 161px;
    height: 46px; */
  font-family: Arial, Helvetica, sans-serif;
  font-style: bold;
  border-radius: 10px;
  align-items: center;
  /* color:#000000; */
  border: 2px solid #000000;
  /* background: #FFFFFF; */
  width: 330px;
  height: 76px;
  margin-bottom: 15px;
}
#pd {
  display: block;
}
#bu {
  color: #000000;
  width: 350px;
  /* justify-content: flex-start; */
}
#bu2 {
  color: #000000;
  width: 350px;
}
#contents_prompt {
  left: 2%;
  height: 40%;
  width: 85%;
  margin: 12;
  border-width: 1;
  padding: 10;
}
#col {
  margin-top: 500px;
  border-radius: 10px;
  height: 200px;
  width: 80%;
  margin-left: -22%;
  margin-right: 4%;
}
#tf {
  height: 90%;
  width: 90%;
}
#col2 {
  margin-top: 200px;
  /* margin-right: 240px; */
  border-radius: 10px;
  height: -7%;
  width: 94%;
  margin-left: 12%;
}
#dis {
  height: 100px;
  display: flex;
}
#words {
  color: #ababab;
  font-size: 30px;
  margin-right: 20px;
  margin-left: 30px;
}

#prof {
  /* text-align: start; */
  /* position: absolute; */
  /* left: 0px; */
  width: 2480px;
  font-family: Arial, Helvetica, sans-serif;
  color: #52b1bb;
  font-weight: lighter;
  text-align: start;
  margin-left: 4%;
  /* margin-bottom: 120%; */
}
#profpersonal {
  margin-bottom: 100px;
  text-align: start;
}
#profpersonal2 {
  margin-bottom: 60px;
  margin-top: 60px;
  display: flex;
  text-align: start;
  font-size: 38px;
  /* align-items: center; */
  flex-direction: column;
}
#hr {
  background-color: #dcdcdc;
  width: 90%;
  height: 1px;
  margin-left: 20px;
  border: none;
}
#colcol {
  display: block;
}
#colcol2 {
  display: block;
  /* left: 50%; */
}
#hrcol {
  background-color: #dbdbdb;
  margin-left: 30px;
  border: none;
  height: 1px;
}
#pass {
  display: flex;
}
#hrcol2 {
  /* border-top: #DBDBDB; */
  /* border-top: #000000; */
  color: #dbdbdb;
  margin-left: 30px;
  /* border: 0 none; */
}
#adda {
  color: #ababab;
}
#addanov {
  color: #ababab;
}

#appBar {
  position: absolute;
  /* width: 100% - 440; */
  width: 5059px;
  height: 90px;
  margin-left: 40px;
  background-color: #399faa;
  background-image: url("https://s3-alpha-sig.figma.com/img/fa79/eb47/c5de70e31559c9a6962f8b8d452d476b?Expires=1673827200&Signature=BH~yOHLJahYrBNZ78Yo7jiZOvyX8~blS-PzvUeWrQmnldHagX~8epP~C4xi78QbmdQYR6AwZ1QZjpDvWhc-0xrFI~KTSqSbEjGN9PxEJKzEG0I3SO5yBHeqC4SDy1-mzjjSK1lR8PXH1B56XICpKmAeNUz4HF-WYGP2d-ul2AafUAXcpYb8bL37zNvJbSWywh3n7oeIJiY5uUkbU0T8Fax1O9MISSaDeIVBxLIbtOYOM~UYMiTkHtFOQ8mS5ikmSIqFnRudmnDRuJ3RI2FstiFOAfkhlaDYte4vRoRzjhQIkvizy2p84c4p48wSUrXrHyb7Vq4oy0r6qHm0pD-S~Wg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
  background-position: 48% 17%;
  background-size: 7%;
  background-repeat: no-repeat;

  /* background-color: orange; */
  /* margin-right: 40px; */
}
#text {
  color: #ffffff;
}

#list {
  position: relative;
  overflow: hidden;
}
#tagbox {
  font-size: 20px;
  background-color: #85ebe1;
  height: 130px;
  width: 440px;
}
#tagss {
  flex-direction: column;
  font-size: 70%;
  font-weight: bold;
  margin-left: 2%;
}

#prompty {
  left: 0%;
  color: #000000;
  font-size: 70%;
  font-weight: bold;
  text-align: left;
}
#tagy {
  color: #000000;
  font-size: 70%;
  font-weight: bold;
}
#SOP {
  display: flex;
}
#sopsop {
  color: #52b1bb;
  margin-left: 1%;
}
#flexy {
  display: flex;
}
#novnov {
  color: #52b1bb;
  margin-left: 30%;
}
#sopsop10 {
  color: #52b1bb;
  /* margin-left: 20%; */
}
#sopsop2 {
  color: #52b1bb;
  /* margin-left: 2%; */
  margin-left: 2%;
  margin-right: 2%;
}
#sopsop3 {
  color: #52b1bb;
  margin-left: 2%;
  margin-right: 2%;
}
#sprompt {
  color: #52b1bb;
  left: -30%;
  /* margin-right: 85%; */
}
#horiline {
  background-color: #eeeeee;
  font-weight: lighter;
  height: 1px;
  border: 0 none;
}
#flexmode {
  display: flex;
  margin-left: 8%;
}

#spropmpthori {
  background-color: #dbdbdb;
  color: #dbdbdb;
  font-weight: lighter;
  height: 1px;
  border: 0 none;
}
#rocketicon {
  width: 350px;
  height: 350px;
}
#boxbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 750px;
  height: 750px;
  border: 2px solid #000;
  box-shadow: 24;
}
#line {
  margin-left: 42.5%;
  margin-right: 5%;
  background-color: #dcdcdc;
  height: 1px;
  /* margin-left: 20px; */
  border: none;
}
#line2 {
  margin-left: 42.5%;
  margin-right: 5%;
  background-color: #dcdcdc;
  height: 1px;
  /* margin-left: 20px; */
  border: none;
}

#icon_colors {
  position: absolute;
  color: #52b1bb;
  /* margin-right: 455%; */
  /* left: -90%; */
  left: 0;
}

/* #nav-bar{
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: -9px 9px 20px 0 rgba(0,31,68,.03);
} */

.navbar {
  margin-top: 80px;
  overflow: hidden;
  /* background-color: #52B1BB; */
  height: 130px;
  /* background-image:  url("https://s3-alpha-sig.figma.com/img/fa79/eb47/c5de70e31559c9a6962f8b8d452d476b?Expires=1667174400&Signature=YU68FZeUv~zCx0rUNNiJpiYzKlrNQ8izTeDpoYbh0I9w~gO-xdlckYtKECJCVu0R6Ag5VsfKMyVR8Q88FRJfHXahSLxDbmwgUHphIYICqztOEknyRF1yEWwunTuqDMR-PbjDBMQyBenCkjPnS3IivqxuVE9yDn7nsBjtIbmjX2nw6I~VUFDChwRlg0xUZiNJ7J3j24HZ5w9KCQT~GfJkq6JcwlwAGT8ScATpGi84yBGQTEI6QC3qmq1jPQl8Oikbe1kq0IRSZVWpVhTPMPDOeGDI3gaPtRMNoipDhWHUsfG3TV1EYHBQ-bgnv5YOZDYSJjTDWP~F2U8dYYO5I-coUw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"); */
  background-position: center left 612px;
  background-repeat: no-repeat;
  background-size: 8%;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: right;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 30px;
  font-family: Plus Jakarta Sans;
  border: none;
  outline: none;
  color: black;
  font-weight: 500px;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: #dffaf7;
  margin-left: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.back {
  background-color: #ababab;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* .dropdown-content a:hover {
  background-color: #ddd;
} */

.dropdown:hover .dropdown-content {
  display: block;
}

#creed {
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 12%;
}
#social {
  font-family: "Inter";
  font-size: 680%;
  color: #101828;
}
#social2 {
  font-size: 280%;
  font-family: "Inter";
}
#join_early {
  margin-top: 4%;
  width: 330px;
  height: 76px;
  border-radius: 10px;
  background-color: #52b1bb;
}
#typewriter_color {
  color: #52b1bb;
}
#stratlogo {
  position: absolute;
  left: 7.94%;
  right: 87.44%;
  top: 27.59%;
  bottom: 27.59%;
  background: #6fbdc9;
}

#join_button {
  width: 100%;
  height: 100%;
  /* border-radius: 6px; */
  font-family: sans-serif;
  color: #ffffff;
  font-weight: bolder;
  margin-top: 7%;
}
#background {
  background-color: #ffffff;
  height: 2981px;
}
#foundersbackground {
  background-color: #ffffff;
  height: 1981px;
}

#purpose {
  margin-top: 40%;
  font-size: 280%;
  color: #52b1bb;
  font-family: "Inter";
}
#create_space {
  color: rgb(0, 31, 68);
  font-size: 580%;
  font-weight: bolder;
  font-family: "Inter";
}
#ul > li {
  display: inline-block;
  margin-top: 9%;
  margin-right: 5%;
}
#whatisstratos {
  color: rgb(0, 31, 68);
  font-size: 680%;
  font-weight: bolder;
  margin-left: 820px;
}
#toomanytimes {
  color: rgb(0, 31, 68);
  font-size: 180%;
  font-weight: light;
  margin-left: 820px;
  line-height: 130%;
}
#meetthefounders {
  color: rgb(0, 31, 68);
  font-size: 680%;
  font-weight: bolder;
  margin-left: 820px;
}
#companybox {
  background-color: #ffffff;
  width: 430px;
  height: 530px;
  margin-left: 4%;
  margin-top: 3%;
  margin-bottom: 3%;
}
#flexyflexy {
  display: flex;
}
#pendo_img {
  height: 220px;
  width: 320px;
  margin-left: 12%;
  margin-top: 6%;
}
#companytext1 {
  color: #000;
  margin-left: 12%;
}
#companytext2 {
  color: #b8b8b8;
  margin-left: 12%;
}

#a {
  display: block;
  color: darkgrey;
  font-weight: bolder;
  font-size: 250%;
  /* margin-right: 5%;
    margin-left: 5%; */
  /* text-align: center;
    padding: 16px;
    text-decoration: none; */
  text-decoration: none;
  font-family: "Inter";
}
#li {
  float: left;
}
#customers {
  margin-top: 40%;
  font-size: 280%;
  color: #52b1bb;
}
#initiate {
  color: rgb(0, 31, 68);
  font-size: 580%;
  font-weight: bolder;
}
#meetcorporate {
  color: rgb(0, 31, 68);
  font-size: 580%;
  font-weight: bolder;
  margin-left: 820px;
  margin-top: 0%;
}
#stratospartnersorg {
  color: rgb(0, 31, 68);
  font-size: 180%;
  font-weight: light;
  margin-left: 820px;
  /* margin-top: 0%; */
}
#block_content_wrap {
  display: flex;
  width: 100%;
  max-width: 1056px;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  font-family: Inter, sans-serif;
}
#grid {
  width: 100%;
  grid-column-gap: 80px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 16px;
}
#shift {
  margin-right: 8%;
}

#pre_footer {
  margin-top: 30px;
  background-color: #afeeee;
  color: #ffffff;
  margin: auto;
  /* text-align: center; */
  height: 900px;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
}
#browse_partners_footer {
  margin-top: 30px;
  background-color: #afeeee;
  color: #ffffff;
  margin: auto;
  /* text-align: center; */
  height: 350px;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
}
#browse_partners_secondfooter {
  /* margin-top: 30px; */
  background-color: #d7f9f5;
  color: #ffffff;
  /* margin: auto; */
  /* text-align: center; */
  height: 1355px;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  /* margin-bottom: 4%; */
  /* margin-top: 3%; */
}
#footer {
  background-color: #48d1cc;
  color: #ffffff;
  /* margin: auto; */
  align-items: center;
  text-align: center;
  height: 900px;
  flex-direction: column;
  /* align-items: flex-start; */
  background-image: url("https://s3-alpha-sig.figma.com/img/fa79/eb47/c5de70e31559c9a6962f8b8d452d476b?Expires=1667174400&Signature=YU68FZeUv~zCx0rUNNiJpiYzKlrNQ8izTeDpoYbh0I9w~gO-xdlckYtKECJCVu0R6Ag5VsfKMyVR8Q88FRJfHXahSLxDbmwgUHphIYICqztOEknyRF1yEWwunTuqDMR-PbjDBMQyBenCkjPnS3IivqxuVE9yDn7nsBjtIbmjX2nw6I~VUFDChwRlg0xUZiNJ7J3j24HZ5w9KCQT~GfJkq6JcwlwAGT8ScATpGi84yBGQTEI6QC3qmq1jPQl8Oikbe1kq0IRSZVWpVhTPMPDOeGDI3gaPtRMNoipDhWHUsfG3TV1EYHBQ-bgnv5YOZDYSJjTDWP~F2U8dYYO5I-coUw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA");
  background-position: top 150px left 812px;
  background-size: 400px;
  background-repeat: no-repeat;
}
#firstfooter {
  font-size: 180%;
  /* display:grid; */
  /* height: 100%; */
  /* margin-left: 220px; */
  /* height: 24%; */
  /* font-display:inherit; */
  margin-top: 6%;
  text-align: left;
  margin-left: 820px;
}
#andrew {
  height: 120px;
  color: #48d1cc;
}
#andrew2 {
  height: -12px;
  color: #48d1cc;
}
/* #boxxy{

} */

#secondfooter {
  font-size: 180%;
  display: block;
  text-align: left;
  margin-left: 820px;
  margin-top: 1%;
}

#firststrat {
  margin-top: 830px;
  font-size: 480%;
  display: block;
  text-align: left;
  margin-left: 820px;
  /* margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */

  /* text-align: center; */
}

#joinwaitlist {
  font-size: 280%;
  margin-left: 820px;
  font-weight: bold;
  /* text-align: center; */
}

#sign_early {
  margin-top: 4%;
  width: 330px;
  height: 76px;
  border-radius: 10px;
  background-color: #52b1bb;
  margin-left: 820px;
}
#signup_button {
  width: 100%;
  height: 100%;
  /* border-radius: 6px; */
  font-family: sans-serif;
  color: #ffffff;
  font-weight: bolder;
  margin-top: 7%;
}

#email_input {
  width: 830px;
  height: 130px;
  border-radius: 10px;
  margin-right: 998px;
  margin-top: 2%;
  background-color: #dffaf7;
  font-size: larger;
  margin-right: 0%;
  margin-left: -29%;
}
#signup_input {
  width: 530px;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 8%;
  margin-left: 8%;
}
#signup {
  color: #52b1bb;
  margin-left: 5%;
  margin-bottom: 15%;
  border-color: black;
}
#subsub {
  background-color: #001f44;
  color: #fff;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: 230px;
  height: 100px;
  border-radius: 10px;
  margin-left: 25%;
}

#column {
  float: left;
  width: 33.33%;
  padding: 5px;
}
#row {
  content: "";
  clear: both;
  display: flex;
  margin-left: 8%;
}
#img {
  border-radius: 60%;
  height: 450px;
  width: 450px;
}
#img2 {
  border-radius: 60%;
  height: 450px;
  width: 450px;
}
#team_dedication {
  margin-left: 820px;
  align-items: center;
  color: rgb(0, 31, 68);
  font-size: 280%;
  font-weight: bolder;
  margin-top: 8%;
}
#foundernames {
  color: rgb(0, 31, 68);
  font-size: 280%;
  margin-top: 4%;
}
#foundertitles {
  font-size: 140%;
  color: #48d1cc;
}
#subsub2 {
  margin-left: -7%;
  width: 230px;
  height: 100px;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-decoration: none;
  background-color: #001f44;
  color: #fff;
  border-radius: 10px;
}
#individualLayer {
  display: flex;
  font-size: 20px;
  /* margin-bottom: 18%; */
}
#iconlayername {
  display: flex;
  font-size: 10px;
}
#dehaze {
  margin-left: 2%;
  margin-right: 3%;
  height: 30px;
  width: 30px;
  align-items: center;
  margin-top: 1.3%;
  color: #a9a9a9;
}

#indlayer {
  background-color: #eaeaea;
  width: 460px;
  height: 40px;
  color: #626262;
  font-size: 40%;
  border-radius: 20px;

  /* margin-bottom: %; */
  /* text-align:start; */
  vertical-align: baseline;
  line-height: 55px;
  margin-bottom: 4%;
  /* margin-left: 3%; */
}
#indlayer2 {
  background-color: #eaeaea;
  margin-bottom: 4%;
  width: 460px;
  height: 40px;
  color: #626262;
  font-size: 24%;
}
#loc {
  margin-top: 10%;
  margin-right: 2%;
}

#profName {
  color: #000000;
  font-weight: lighter;
  /* margin-left: 30%; */
}

#profJT {
  color: #2a2e33;
  font-weight: lighter;
  /* margin-bottom: 44%; */
}

#border {
  background-color: #f3f9fb;
  width: 560px;
  height: 170px;
  overflow: auto;
  border-radius: 20px;
}
#innerborder {
  /* margin-top: 3%; */
  background-color: #ffffff;
  width: 480px;
  margin-bottom: 6%;
  font-size: 60%;
  /* margin-right: 23%; */
  /* margin-top: 4%; */
}
#orderedlist {
  margin-left: 4%;
}
#hicon {
  margin-bottom: 4%;
  position: absolute;
  right: 160px;
  margin-top: -1.3%;
  /* margin-bottom: 13%; */
  /* align-items: flex-end; */
}
#layerwords {
  margin-top: -1.3%;
}
#highlightbutton {
  pointer-events: none;
}

#highlighticon {
  transform: scale(0.5);
  /* margin-bottom: 14%; */
  /* float: right; */
  /* align-items: end; */
  /* right: 72%; */
}
#more_details {
  margin-left: 70%;
  margin-top: 1%;
  color: #515151;
  /* position: fixed; */
  /* right: auto; */
}
#mvI {
  position: fixed;
  /* margin-left: 14%; */
}
#posterName {
  margin-top: 1%;
}
#modalsizing {
  width: 400px;
  height: 400px;
  background-color: #f3f5fc;
  font-size: 30px;
}
#modalsend {
  border-radius: 40px;
  height: 100px;
  width: 250px;
  background-color: #52b1bb;
  color: #ffffff;
  margin-top: 2%;
  font-weight: bold;
  /* font-size: 80px; */
}
#modalflex {
  display: flex;
}
#post {
  background-color: #ffffff;
  height: 310px;
  width: 2660px;
  margin-left: -4%;
  margin-top: 6%;
  border-radius: 30px;
  overflow-y: scroll;
  /* height: 200px;
    width: 80%; */
}
#nameOnPost {
  color: #000000;
  font-size: 1.93em;
  margin-left: 4%;
  display: flex;
  /* margin-top: 12%; */
  /* margin-top: 4%; */
}
#dateOnPost {
  color: #929292;
  margin-bottom: 0.5%;
  margin-left: 4%;
}
#promptOnPost {
  color: #52b1bb;
  font-size: 80%;
  margin-left: 1.5%;
  font-size: 1.93em;
}
#promptMessage {
  color: #707070;
  margin-bottom: 0.7%;
  margin-left: 1.5%;
  font-size: 1.4em;
}
#promptHL {
  /* background-color: #DDDDDD; */
  background-color: #dddddd;
  height: 1px;
  border: 0;
  /* width: 900px; */
  width: 99.3%;
}
#postIcons {
  display: flex;
}
#thumbsUp {
  margin-left: 2%;
  margin-right: 8%;
  margin-top: 0.5%;
  /* pointer-events: none; */
  /* background-color: #515151; */
}
#thumbsUpIcon {
  color: #515151;
  /* fill: solid; */
}
#commentsIcon {
  color: #515151;
}
#shareIcon {
  color: #515151;
}
#comments {
  margin-right: 16%;
  margin-top: 0.5%;
}
#share {
  margin-top: 0.5%;
}
#searchsearch {
  background-color: #f3f5fc;
  width: 850px;
  height: 100px;
  border-radius: 50px;
  margin-top: 25%;
  margin-bottom: 15%;
  padding: 0 20px;
  font-size: 50px;
  /* margin-bottom: 160%; */
  /* margin-right: 85%; */
}
#searchsearch::placeholder {
  margin-left: 4%;
  font-size: 50px;
}
#searchsearch2 {
  background-color: #f3f5fc;
  width: 850px;
  height: 100px;
  border-radius: 50px;
  margin-top: 2%;
  margin-bottom: 15%;
  /* margin-right: 200%; */
  /* margin-bottom: 160%; */
  /* margin-right: 85%; */
}
#inputbase {
  margin-left: 8%;
  font-size: 45px;
  margin-top: 3%;
  /* margin-bottom: 14%; */
  /* height: 2px; */
}
#inputbase2 {
  margin-left: 15%;
  font-size: 45px;
  margin-top: -8%;
  /* margin-top: 3%; */
  /* margin-bottom: 194%; */
  /* height: 2px; */
}
#searchicon {
  margin-right: 85%;
  margin-bottom: 290px;
}
#searchicon2 {
  margin-right: 80%;
  margin-bottom: -70px;
  /* margin-bottom: 90px; */
}
#title {
  margin-left: 8%;
  color: #52b1bb;
}
#JCLOGO {
  width: 90px;
  height: 90px;
  border-color: #dddddd;
  border-radius: 50px;
  border-style: solid;
  margin-left: 3%;
  margin-top: 3%;
  /* border-color: #E0E0E0; */
}
#titlediv {
  display: flex;
  flex-direction: row;
}
#company2 {
  /* margin-left: 8%; */
  color: #000000;
  /* margin-bottom: 55%; */
}
#experience {
  margin-left: 8%;
  color: #707070;
}
#savebookmark {
  position: fixed;
  color: #acacac;
  margin-top: 2%;
  /* right: 120%; */
  margin-left: 21%;
}
#arrowfor {
  position: fixed;
  right: 4%;
  color: #bdbdbd;
  font-size: 12px;
}
#hr_job {
  height: 1px;
  background-color: #dddddd;
  border: none;
}
#jobcard_roletypes {
  margin-left: 21%;
  display: flex;
  color: #000000;
  margin-top: -2%;
}
#typeOfRole {
  margin-right: 8%;
  /* margin-bottom: 4%; */
}
#jobapply {
  width: 750px;
  height: 70px;
  border-radius: 20px;
  background-color: #52b1bb;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
}
#createJob {
  position: absolute;
  left: 1112px;
}
#initialpage {
  background-color: #f0f0f0;
  height: 2000px;
  overflow-y: scroll;
}
#pagebox {
  height: 103px;
  width: 1222px;
  background: #ffffff;
  border: 2px solid #ffffff;
  margin: auto;
  margin-bottom: 1%;
  margin-top: 190px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
#screeningquestions {
  height: 603px;
  width: 1222px;
  background: #ffffff;
  border: 2px solid #ffffff;
  margin: auto;
  margin-bottom: 20px;
}
#benefits {
  /* margin-top: 12px; */
  height: 303px;
  width: 1222px;
  background: #ffffff;
  border: 2px solid #ffffff;
  margin: auto;
  margin-bottom: 20px;
}
#screeningquestionsheader {
  margin-left: 4%;
  margin-right: 1%;
  font-weight: bold;
}
#questionCAJ {
  position: absolute;
  display: flex;
  flex-direction: row;
  /* left: 1150px; */
}
#qualifications {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 1150px;
}
#jobdetailbuttons {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 1113px;
  margin-top: 40px;
  margin-bottom: 140px;
}
#createjobdetailsbox {
  height: 1100px;
  width: 1222px;
  background: #ffffff;
  border: 2px solid #ffffff;
  margin: auto;
  margin-bottom: 1%;
}
#addSkills {
  height: 300px;
  width: 1222px;
  background: #ffffff;
  border: 2px solid #ffffff;
  margin: auto;
}
#page1 {
  color: #52b1bb;
  font-size: 25px;
  margin-top: 2.5%;
  margin-left: 18%;
}
#page21 {
  color: #000000;
  font-size: 25px;
  margin-top: 2.5%;
  margin-left: 18%;
}
#page2 {
  font-size: 25px;
  color: #000000;
  margin-top: 2.5%;
  margin-right: 18%;
}
#page22 {
  font-size: 25px;
  color: #52b1bb;
  margin-top: 2.5%;
  margin-right: 18%;
}
#jdetails {
  margin-left: 4%;
  margin-right: 1%;
  font-weight: bold;
}

#hrjd {
  background-color: #dcdcdc;
  width: 100%;
  height: 1px;
  border: none;
}
#headers_jd {
  color: #c0c0c0;
  margin-left: 4%;
  font-weight: 350;
}
#doubleheaders_jd {
  color: #c0c0c0;
  margin-left: 9%;
  font-weight: 350;
  /* height: 65px; */
}
#doubleheaders_jd2 {
  color: #c0c0c0;
  margin-left: 59%;
  font-weight: 350;
  white-space: nowrap;
}
#doubleheaders_jd3 {
  color: #c0c0c0;
  margin-left: 29%;
  font-weight: 350;
  white-space: nowrap;
}
#smallerinput_jd {
  margin-left: 4%;
  width: 1125px;
  height: 65px;
  border: 2px solid #dcdcdc;
  border-radius: 6px;
}
#compenastion_input {
  margin-left: 4%;
  width: 1125px;
  height: 65px;
  border: 2px solid #dcdcdc;
  border-radius: 6px;
  margin-top: 45px;
}
#miniinput_jd {
  margin-left: 9%;
  width: 562px;
  /* height: 95px; */
  border: 2px solid #dcdcdc;
  border-radius: 6px;
}
#miniinput_jd2 {
  /* margin-left: auto; */
  position: absolute;
  right: 1140px;
  width: 562px;
  height: 62px;
  border: 2px solid #dcdcdc;
  border-radius: 6px;
}
#jobdescriptioninput {
  width: 1125px;
  margin-left: 4%;
  height: 350px;
  border: 2px solid #dcdcdc;
  border-radius: 6px;
}
#skillsdiv {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap;
  justify-content: space-between; */
}
#firstrow {
  display: flex;
  flex-direction: row;
}
#upto {
  color: #dcdcdc;
}
#jobdetailcancel {
  width: 114px;
  height: 65px;
  margin-right: 8px;
  background: #c0c0c0;
}
#jobdetailnext {
  width: 114px;
  height: 65px;
  background: #52b1bb;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
