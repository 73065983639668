.signup2-container {
    display: flex;
    background-image: url(../../Assets/Background-1.png);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
}

.signup2-container img {
    width: 153px;
    height: 33px;
    margin-bottom: 25px;
}

.signup2-form-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
}

.form-heading {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
    justify-self: flex-start;
    align-self: flex-start;
    margin-left: -27px;
}
.arrow-icon {
    width: 20px;
    height: 20px;
    color: #388698;
}

.signup2-form-container h6 {
    font-family: "Helvetica";
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
}

.form-enter-the-details {
    font-family: "Helvetica";
    font-weight: 300;
    line-height: 18px;
    color: #767676;
}

.signup2-container h5 {
    font-family: "Helvetica";
    font-weight: 300;
    font-size: 10px;
    line-height: 18px;
    color: #767676;
    margin-bottom: 2px;
}

.signup-form {
    padding: 10px;
    width: 100%;
}

/*.signup-form input {
    display: flex;
    height: 20px;
    background: #f3f9fb;
    border: 1px solid hsl(184, 22%, 85%);
    border-radius: 3px;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.signup-form input[type="date"] {
    display: block;
    min-height: 1.2em;
    min-width: 96%;
    padding: 4px;
}*/

/*.date-input {
    min-width: 95%;
}*/

/*.signup-form input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}*/

.login-link {
    cursor: pointer;
    font-size: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .signup2-form-container {
        width: 40%;
    }

    .form-heading {
        width: 67%;
    }
}

@media screen and (max-width: 700px) {
    .signup2-form-container {
        width: 50%;
        height: 340px;
        padding-bottom: 60px;
    }

    .form-heading {
        width: 70%;
    }
}

@media screen and (max-width: 455px) {
    .signup2-form-container {
        width: 60%;
        height: auto;
    }

    .form-heading {
        width: 67%;
    }

    .login-link {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 375px) {
    .signup2-form-container {
        width: 60%;
        height: 340px;
    }

    .form-heading {
        width: 87%;
    }

    .login-link {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 345px) {
    .signup2-form-container {
        width: 60%;
        height: auto;
        justify-content: flex-start;
        padding: 35px;
    }

    .form-heading {
        width: 78%;
    }

    .login-link {
        margin-bottom: 40px;
    }
}
