:root {
    --primary: #52b1bb;
    --secondary: #399faa;
    --dark: #14213d;
    --gray: rgb(147, 147, 147);
    --font-family: "Roboto, Source Sans Pro";
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
