.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;
}

.footer-container_links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  vertical-align: middle;
  width: 80%;
}

.footer-links_section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.footer-links_section h1 {
  font-family: "helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #98a2b3;
  margin-bottom: 16px;
}

.footer-container_links a {
  font-family: "helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  text-decoration: none;
  border: none;
  outline: none;
}

.footer-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  padding-top: 32px;
  margin-top: 30px;
  border-top: 1px solid #d6d8db;
  width: 80%;
}

.footer-logo img {
  width: 69.95px;
  height: 35.85px;
}

.footer-logo h3 {
  font-family: "helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}

@media screen and (max-width: 820px) {
  .footer-links_section h1 {
    font-size: 13px;
    line-height: 19px;
  }

  .footer-container_links a {
    font-size: 15px;
    line-height: 23px;
  }

  .footer-container {
    padding-left: 110px;
    padding-right: 110px;
  }

  .footer-container_links {
    width: 85%;
  }
}

@media screen and (max-width: 770px) {
  .footer-container {
    padding-left: 90px;
    padding-right: 90px;
  }

  .footer-container_links {
    width: 87%;
  }
}

@media screen and (max-width: 675px) {
  .footer-container {
    padding-top: 100px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .footer-container_links {
    width: 89%;
  }

  .footer-logo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 635px) {
  .footer-container {
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .footer-container_links {
    width: 90%;
  }
}

@media screen and (max-width: 475px) {
  .footer-container {
    padding-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .footer-container_links {
    width: 93%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .footer-links_section {
    margin-right: 25px;
    margin-bottom: 35px;
  }

  /* .footer-links_section a {
    /* margin-bottom: 5px; */
  /* } */
}
