.navbar-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    background-color: #FFFFFF;
}

.navbar-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 47px;
    justify-self: center;
    align-self: center;
}


.navbar-logo img{
  width:69.95px;
  height:39.85px;
}

.nav-links{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    padding-left: 47px;
    /* padding-right: 47px; */
    /* padding-right: 7px; */
}

.nav-links a{
    text-decoration: none;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: black;
    border: none;
    outline: none;

}



.nav-links button{
  padding: 8px;
  gap: 8px;
  width: 119px;
  height: 39px;
  background: linear-gradient(96.34deg, #81C9CF 0%, #60ADB9 100%);
  border-radius: 32px;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
  color: white;
}


.icon-button{
  display: none;
}

.app-nav{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #60ADB9;
  color: white;
  padding: 14px;
  border-radius: 4%;
  top: 50px;
  right: 70px;
  transition: 0.2s ease;
}

.app-nav a{
  text-decoration: none;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: white;
  border: none;
  outline: none;
}


.app-nav button{
  padding: 3px;
  width: 58px;
  height: 25px;
  background: linear-gradient(96.34deg, #81C9CF 0%, #60ADB9 100%);
  border-radius: 32px;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
  color: white;
}


@media screen and (max-width: 870px) {

  .navbar-logo{
    display: flex;
    padding-left: 35px;
    justify-content: flex-start;
    align-items: flex-start;
}


.navbar-logo img{
  width:49.95px;
  height:29.85px;
  margin-bottom: 40px;
}

.nav-links{
  width: 55%;
  padding-right: 35px;
  align-items: center;
}
  
.nav-links button{
  padding: 7px;
  width: 110px;
  height: 39px;
  border-radius: 30px;
  margin-bottom: 15px;
  color: white;
}

}




@media screen and (max-width: 775px) {

  .navbar-logo{
    display: flex;
    padding-left: 30px;
    justify-content: flex-start;
    align-items: flex-start;
}


.navbar-logo img{
  width:45.95px;
  height:23.85px;
  margin-bottom: 40px;
}

.nav-links{
  width: 60%;
  padding-right: 30px;
  align-items: center;
}
  

}






@media screen and (max-width: 675px) {

  .navbar-logo{
    display: flex;
    padding-left: 28px;
    justify-content: flex-start;
    align-items: flex-start;
}

.nav-links{
  width: 65%;
  padding-right: 28px;
  align-items: center;
}
  

.nav-links a{
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.06em;

}

}




@media screen and (max-width: 675px) {

  .navbar-container{
    width: 100%;
    display: flex;
    justify-self: center;
    align-self: center;
  }
  .navbar-logo{
    display: flex;
    padding-left: 28px;
    justify-content: flex-start;
    align-items: flex-start;
    }

  .nav-links{
  display: none;
  }
    
  .icon-button{
    display: flex;
    padding-right: 0px;
  }

}
