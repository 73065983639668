.stratosstroke{
    color: #CFE8EC;
    position: absolute;
    width: 576.84px;
    height: 491.75px;
    left: 500.58px;
    top: -70.41px;

    border: 4px solid #CFE8EC;
    transform: matrix(-1, -0.08, -0.08, 1, 0, 0);
}
.stratosblog{
    position: absolute;
    width: 273px;
    height: 60px;
    left: 120.62px;
    right: 87.44%;
    top: 92.8px;

    /* Display lg/Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height, or 125% */

    letter-spacing: -0.02em;

    color: #101828;
    margin-top: 200px;

}
.stratospara{
    position: absolute;
    width: 520.36px;
    height: 93.84px;
    left: 2763.65px;
    top: 110.8px;
    /* right: 887.44%; */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* or 130% */

    letter-spacing: -0.02em;

    color: #9799AC;
    margin-top: 200px;

}

.articletag{
    width: 351.47px;
height: 21px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14.6447px;
line-height: 21px;
/* identical to box height, or 143% */


color: #6FBDC9;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.title{
    font-size: 150px;
    width: 309.63px;
    height: 34px;

    font-family: 'Inter';
    font-weight: 600px;
    line-height: 33px;
    /* identical to box height, or 133% */


    color: #101828;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.articleblurb{
    width: 341px;
    height: 76px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16.7368px;
    line-height: 25px;
    /* or 150% */


    color: #667085;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.articleimg{
    /* position: absolute; */
    height: 276px;
    left: 0px;
    right: 0px;
    top: 0px;
    background-image: url("https://s3-alpha-sig.figma.com/img/9ca4/12fa/840de131974162b09d68033e23a850b5?Expires=1676851200&Signature=L84pf8fHzJrFv2IB9Z0PVuUFPiyA0tjqYHsIOIpfeRuaXQP2tiThoEsp13nh6m0R6EeSCNLfLXwK7fqUR-aomjFvx2F6t-TxssZ7~M2~xb5-zk1kKftOVpNOV0cvn2gRBReOSpm-6pVLE5vRGN9uDG6xz5zbJfp9tw0AtTNheDy9dAwD4ya8YESrE1aAshZhFkb0mtIp4wcZGspAMUUm-QQoYqzYd7LsF0B66Dwqm73QPNkHxMDyYDT5rF5Ypr5-867zFV9zCX-uDrLH7rtLBlKYiHTk5Bk6eCFUG8vJU37dAyDBzewwfuQNvAbuvhSmAJtSdQAPKcPXvA3ooNR3qQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
    background-position: -8950px;
    width: 402px;
    height: 276px;


}

.indivPost{
    margin-top: 300px;
    margin-left: 120.62px;
    display: inline-block;
    /* border: 1px solid #ccc; */
}

.blogPosts{
    display: inline-block;
}