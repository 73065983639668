.pipeline-card-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mypipeline-container{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 30px;

}

.mypipeline-userinfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mypipeline-user-profile{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.mypipeline-user-profile img{
    border-radius: 100%;
}

.mypipeline-user-profile h2{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 4px;
    color: var(--dark);
}

.mypipeline-user-profile h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark);
    opacity: 0.5;
}


.mypipeline-user-actions{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px
}

.message{
    background-color: var(--primary);
    color: white;
    font: bold;
    font-size: 10px;
    width: 100px;
    margin-right: 7px;
}


.message:hover{
    background-color: white;
    color: var(--primary);
    border: 1px solid var(--primary);
}

.reject{
    background-color: white;
    color:#CD5656;
    border: 1px solid #CD5656;
    font-weight: 400;
    font-size: 10px;
    width: 100px;
    margin-right: 7px;
}

.archive{
    background-color: white;
    color: black;
    border: 1px solid black;
    font-weight: 400;
    font-size: 10px;
    width: 100px;
}

.mypipline-user-expierence{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.user-experience{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px dashed #d6d6d6;
    margin-top: 10px;
}

.company{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
}

.user-experience h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    opacity: 0.5;
}

.company h2{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
}




.user-education{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px dashed #d6d6d6;
    margin-top: 20px;
    padding-top: 10px;
}


.school{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
}

.user-education h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    opacity: 0.5;
}

.school h2{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
}

.school h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--dark);
    opacity: 0.5;
}


.user-layers{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px dashed #d6d6d6;
    margin-top: 20px;
    padding-top: 10px;
}

.user-layers h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    opacity: 0.5;
}



.layers{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3px;
    flex-direction: row;
}

.layers h2{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: var(--dark);
    border: 1px solid #d6d6d6;
    margin: 10px 4px 0px 0px;
    padding: 2px 6px;
    border-radius: 4px;
    opacity: 0.8;
}


.user-workplace{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px dashed #d6d6d6;
    margin-top: 20px;
    padding-top: 10px;
}


.user-workplace h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    opacity: 0.5;
}


.workplace{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3px;
    flex-direction: row;
}

.workplace h2{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: var(--dark);
    border: 1px solid #d6d6d6;
    margin: 10px 4px 0px 0px;
    padding: 2px 6px;
    border-radius: 4px;
    opacity: 0.8;

}