.container{
    display: flex;
    background-color: #F0F3F5;
    flex-direction: column;
    scroll-behavior: smooth;
}

.job-container{
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    justify-content: space-around;
    padding: 10px;

}

.jobpage-tab{
    width: 100%;
}

.tab-pannel{
    background-color: #F0F3F5;
    width: 100%;
    margin: 0;
    padding: 0;
}

.job-details{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid rgb(222, 222, 222);
    padding-bottom: 10px;
    scroll-behavior: smooth;

}



.job-details_position{
    display: flex;
    justify-content: center;
    align-items: center;
}

.job-details_position img{
    height: 50px;
    width: 50px;
}

.job-details_position-info{
    padding: 0;
}

.job-details_position-info h4{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 2.5px;
    color: var(--dark);
}

.job-details_position-info-time{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.job-details_position-info-time p{
    line-height: 0px;
    margin-top: -8px;
    font-size: 10px;
    font-family: var(--font-family);
    font-weight: 400;
}

.job-details_location{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.job-details_location-icon{
    border: 1px dashed gray;
    margin-right: 6px;
    padding-left: 2px;
    padding-right: 2px;
}

.job-details_location-info p{
    line-height: 4px;
    font-size: 10px;
    font-family: var(--font-family);
    font-weight: 400;
    
}

.job-edit{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.job-edit_cross{
    margin-right: 5px;
}

.job-edit_cross button{
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;   
}

.job-edit_cross button{
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    
}

.job-nav{
    align-items: center;
    background-color: #ffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    padding-top: 20px;
    padding-left: 8%;
    
}

.job-nav button{
    background: none;
    outline: none;
    border: 0;
    cursor: pointer;
    line-height: 4px;
    font-size: 13px;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--dark);
    opacity: 0.5;
}

.job-details-heading{
    width: 80%;
    display: flex;
    align-self: center;
}

.job-details-heading h4{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--dark);
}

.job-settings_job-details{
    width: 80%;
    display: flex;
    background-color: #ffff;
    margin-top: 10px;
    flex-direction: row;
    align-self: center;
    padding: 10px;
    padding-bottom: 30px;
    border-radius: 5px;
    scroll-behavior: smooth;


}

.job-settings_job-details_info{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.job-settings_job-details_info-title{
    width: 100%;
}

.job-settings_job-details_info-title p{
    font-family: var(--font-family) ;
    font-weight: 400;
    font-size: 16px;
    line-height: 0.2px;
    color: var(--dark);
    opacity: 0.5;
}

.job-settings-input{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(217, 217, 217);
    padding: 5px;
    padding-left: 8px;
    border-radius: 2px;
}

.job-settings-input input{
    width: 90%;
    outline: none;
    border: none;
}

.job-settings_job-details_info-location{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 15px;
}


.job-settings_job-details_info-loc p{
    font-family: var(--font-family) ;
    font-weight: 400;
    font-size: 16px;
    line-height: 0.2px;
    color: var(--dark);
    opacity: 0.5;
}

.job-settings_job-details_info-loc{
    width: 47%;
}

.job-settings_job-details_info-emp{
    width: 47%;
}

.job-settings_job-details_info-emp p{
    font-family: var(--font-family) ;
    font-weight: 400;
    font-size: 16px;
    line-height: 0.2px;
    color: var(--dark);
    opacity: 0.5;
}

.job-settings_rejection{
    display: flex;
    width: 80%;
    align-self: center;
    background-color: #ffff;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    flex-direction: column;
    scroll-behavior: smooth;

}

.job-settings_rejection-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.rejection-settings-toggle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rejection-settings-toggle p{
    margin-right: 4px;
}

.job-settings_rejection-heading Typography{
    font-family: var(--font-family) ;
    font-weight: 400;
    font-size: 16px;
    line-height: 0.2px;
    color: var(--dark);
    opacity: 0.5;
}


.job-settings_rejection-heading h4{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--dark);
}

.job-settings_rejection-message{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.job-settings_rejection-message-input{
    width: 80%;
    
}

.job-settings_rejection-message-inpu p{
    font-family: var(--font-family) ;
    font-weight: 400;
    font-size: 16px;
    line-height: 0.2px;
    color: var(--dark);
    opacity: 0.5;
}

.job-settings_rejection-message-input textarea{
    width: 100%;
    height: 94px;
}

.job-settings_rejection-message Button{
    width: 91px;
    background: rgb(221, 221, 221);
    border-radius: 3px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
}

.job-settings_members{
    display: flex;
    width: 80%;
    align-self: center;
    background-color: #ffff;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    flex-direction: column;
}

.job-settings_members-heading{
    padding: 20px;
}

.job-settings_members h4{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--dark);
}

.job-settings_members-cards{
    display: flex;
    justify-content: flex-start;
    padding: 20px;

}

.members{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 100px;
}

.members img{
    border-radius: 100%;
    margin-right: 10px;
}

.members h5{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--dark);
}



.member-positon{
    font-family: var(--font-family) ;
    font-weight: 400;
    font-size: 16px;
    line-height: 0.2px;
    color: var(--dark);
    opacity: 0.5;
}


.job-settings_manage-job-post{
    display: flex;
    width: 80%;
    align-self: center;
    background-color: #ffff;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    flex-direction: row;
    justify-content: space-between;
}

.job-settings_manage-job-post_heading{
    padding:20px
}

.job-settings_manage-job-post_heading h5{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--dark);
}

.job-settings_manage-job-post_input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}



.job-settings_manage-job-post_input Button{
    width: 50px;
    background: rgb(221, 221, 221);
    border-radius: 3px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: var(--dark);
    margin-left: 5px;
}

.job-settings_manage-job-post_input p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #14213D;
}

@media screen and (max-width: 690px) {
    .job-settings_rejection-heading{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .job-settings_rejection-message{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}


@media screen and (min-width: 665px){

    .job-details_position img{
        height: 50px;
        width: 50px;
    }

    .job-details_position-info h4{
        font-size: 14px;
       }


        
    .job-details_position-info-time p{
        font-size: 8px;
    }

    .icon{
        height: 10px;
        width: 10px;
    }

    .job-details_location-info p{
        font-size: 8px;
    }
}



@media screen and (max-width: 555px ){

  
.job-details{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.job-settings_members-cards{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.members{
    margin-bottom: 10px;
}

.job-settings_manage-job-post{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.job-settings_manage-job-post_input{
    padding:10px
}

.jobpage-tab{
    width: 100%;
    display: flex;
    flex-direction: column;
}


}