.mobile-container{
    background: url(../Assets/Background-1.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-container img{
    margin-bottom: 40px;

}

.mobile-container_text{
    width: 70%;
    padding: 20px;
    background-color: rgba(0,0,0,0.3);
}

.mobile-container h1{
    font-size: 24px;
    text-align: center;
    color: white;
    font-family: 'Helvetica';
    text-transform: capitalize;
}