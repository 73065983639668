.pipeline-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    background-color: '#F0F3F' ;
    
}

.pipeline-tabs_tabs-container{
    display: flex;
    background-color: white;
    height: 240px;
    margin-left: 8%;
    margin-right: 80px;
    width: 280px;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.tab{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px dashed rgb(183, 183, 183);
    border-radius: 5px;
}

.tab-panel_container{
    background-color: '#F0F3F';
}