.job-settings-container{
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-behavior: smooth;

}

.job-settings-container_nav{
    margin-top: 30px;
    background-color: #ffff;
    padding: 10px;
    width: 80%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    border-radius: 5px;
}

.job-settings-container a{
    background: none;
    outline: none;
    border: none;
    font-size: 13px;
    font-family: var(--font-family);
    font-weight: 400;
    color: rgb(147, 147, 147);
    cursor: pointer;
    text-decoration: none;
}



.job-settings_actions{
    display: flex;
    width: 80%;
    align-self: center;
    padding: 30px;
    margin-top: 20px;
    border-radius: 5px;
    flex-direction: row;


}

.job-settings_actions button{
    width: 147px;
    height: 42px;
    outline: none;
    border: none;
    color: #ffff;
    background-color: var(--primary);
    font-weight: bold;

}

.action-edit{
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-change button{
    color: black;
    font-weight: bold;
    background-color: rgb(217, 217, 217);
    margin-left: 10px;
}
