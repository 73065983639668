.published{
    width: 960px;
    height: 24px;

    /* Text md/Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    color: #6FBDC9;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 150px;
}

.conversation{
    width: 960px;
    height: 60px;

    /* Display lg/Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height, or 125% */

    letter-spacing: -0.02em;

    color: #101828;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 150px;
}
.bloginfo{
    width: 960px;
    height: 60px;

    /* Text xl/Normal */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */


    color: #667085;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 150px;
}
.mainpara{
    /* width: 60px;
    height: 60px; */

    /* Text xl/Normal */
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 1.8;
    /* or 150% */


    color: #000000;


    /* Inside auto layout */

    /* position: absolute; */
    margin-left: auto;
    width: 270px;
    /* margin-bottom: 110px; */
    /* margin-top: 230%; */
}

.blog{
    margin-left: 150px;
    margin-top: 40px;
}