.home{
    background-color: #a7bcff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .containers{
        // display: flex;
        border: 1px solid white;
        border-radius: 10px;
        width: 65%;
        height: 80%;
        display: flex;
        overflow: hidden;

        .sidebar{
            flex: 1;
            background-color: #3e3c61;

            .navbardm{
                display: flex;
                align-items: center;
                background-color: #2f2d52;
                height: 50px;
                padding: 10px;
                justify-content: space-between;
                color: #ddddf7;

                .logo{
                    font-weight: bold;
                }
                .user{
                    display: flex;
                    gap: 10px;

                    img{
                        background-color: #ddddf7;
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    button{
                        background-color: #5d5b8d;
                        color: #ddddf7;
                        font-size: 10px;
                        border: none;
                        cursor: pointer;
                    }
                }


            }
            .search{
                border-bottom: 1px solid gray;

                .searchForm{
                    padding: 10px;
                    input{
                        background-color: transparent;
                        border: none;
                        color: white;
                        outline: none;

                        &::placeholder{
                            color: lightgray;
                        }

                    }
                }
            }

            .userChat{
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                color: white;
                cursor: pointer;

                &:hover{
                    background-color: #2f2d52;
                }
                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .userChatInfo{
                    span{
                        font-size: 18px;
                        font-weight: 500;
                    }
                    p{
                        font-size: 14px;
                        color: lightgray;
                    }
                }
            }
            
            // background-color: #3e3c61;
        }
        .chat{
            flex: 2;

            .chatInfo{
                height: 50px;
                background-color: #5d5b8d;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                color: lightgray;
            }

            .messages{
                background-color: #ddddf7;
                padding:10px;
                height: calc(100% - 160px);
                overflow: scroll;

                .messagedm{
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;

                    .messageInfo{
                        display: flex;
                        flex-direction: column;
                        color: gray;
                        font-weight: 300;

                        img{
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }
                    .messageContent{
                        max-width: 80%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        p {
                            background-color: white;
                            padding: 10px 20px;
                            border-radius: 0px 10px 10px 10px;
                            max-width: max-content;
                        }
                        img{
                            width: 50%;
                            // width: 50px;
                            // height: 50px;
                            // border-radius: 50%;
                            // object-fit: cover;
                        }
                    }

                    &.owner{
                        flex-direction: row-reverse;

                        .messageContent{
                            align-items: flex-end;
                        }

                        p{
                            background-color: #8da4f1;
                            color: white;
                            border-radius: 10px 0px 10px 10px;
                        }
                    }
                }
                        
            }

            .input{
                height: 50px;
                background-color: white;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                input{
                    width: 100%;
                    border: none;
                    outline: none;
                    color: #2f2d52;
                    font-size: 18px;

                    &::placeholder{
                        color: lightgray;
                    }
                }

                .send{
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img{
                        height: 24px;
                        cursor: pointer;
                    }
                    button{
                        border: none;
                        padding:10px 15px;
                        color: white;
                        background-color: #8da4f1;
                        cursor: pointer;
                    }
                }
            }

            
        }
    }

    
}