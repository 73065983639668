.teamDiv{
    position: absolute;
    left: 50%;
    margin-left: -100px;
}
.theTeam{
    font-size: 16px;
    color: #6FBDC9;
    text-align: center;
}
.meet{
   font-size: 48px;
   color: #101828;
   line-height: 60px;
   text-align: center;
}
.smallTeam{
    font-size: 20px;
    color: #667085;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-bottom: 935px;
}
.name{
    color: #101828;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}
.title{
    color: #6FBDC9;
    font-size: 18px;
    line-height: 28px;
}
.past{
    color: #667085;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.image{
    width: 280px;
    height: 296px;
    background-image: url("https://s3-alpha-sig.figma.com/img/261d/783d/a4147da7fea569d1940840845897657f?Expires=1676851200&Signature=kocuXrMZ0HwU6ZFD8HFy5ItQhZQyfppcacKgXg3cbfB9VZTPhKGlq6M3uNmeA8yt3sHcyV2g80rWL7y94v599pv52KAfN32sKdownHKmX~gtBhzRrg8KB3am8QvGpKqypf33cvBDwmgtaeZ4TXZGBlLhQ1S5iUMG7E5XIfC7YWkpx~zUEo3mRTfP2K35EfR2OV1bIKySwDsaND-8TeAL9dE~yicrTNPW1Ic65vWMiSL7E-x3tmlxFGY65U3i3UdYNjpF4jKWLyWdhHe5vXZuTiR5c2lz8kX4FYMFst3Ar-RwnVEs76gVKVMJvuxKqZy4PWSs5wHztA1pxJsQU2E6Kg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4");
    background-position: -150px;

}
.socialicon{
    width: 24px;
    height: 26px;
   color: #98A2B3;
}
.indivBio{
    margin-top: 300px;
    /* position: absolute; */
    /* left: 250%; */
    margin-left: 100px;
    /* border: 1px solid #ccc; */
    display: inline-block;
    align-items: center;
}
.openings{
    position: absolute;
    left: 50%;
    margin-left: -100px;

}
