.job-post_container{
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-around;
    width: 80%;
    padding-bottom: 20px;
    padding-top: 30px;
}


.job-post_description{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    padding: 20px;
    margin-right: 30px;
    margin-left: 70px;
    border-radius: 10px;

}

.job-post_description-desc{
    margin-bottom: 20px;
}

.description-heading{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--dark);
    margin-bottom: 20px;


}

.description-subtitle{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: var(--dark);
    opacity: 0.5;
    margin-bottom: 10px
}

.job-post_description-values{
    padding: 10px;
}

.values-heading{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    padding-bottom: 5px;
}


.heading{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: var(--dark);
    display: inline;
    padding-right: 8px;
}

.subtitle{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: var(--dark);
    opacity: 0.5;

}

.company-jd{
    margin-top: 25px;
}

.job-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.area-of-focus{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.tech-container{
    display: flex;
    flex-direction: row;
}

.tech{
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    margin-top: 4px;
}

.job-info-heading{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
}

.tech-subtitle{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: var(--dark);
    border: 1px solid rgb(194, 194, 194);
    border-radius: 5px;
    padding: 4px;
}

.job-post_analytics{
    display: flex;
    flex: 0.42;
    flex-direction: column;
    align-items: center;
    height: 300px;
    padding: 6px;
    border-radius: 10px;
}

.analytics-heading-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px dashed rgb(182, 182, 182);
}

.analytics-heading{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: var(--dark);


}

.analytics{
    background: #E1F1F2;
    display: flex;
    flex-direction: column;
    border: 1px solid #E1F1F2;
    border-radius: 6px;
    width: 70%;
    margin-bottom: 5px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    }


.title{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
}

.number{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #52B1BB;
}


@media screen and (max-width: 768px) {
    .job-post_container{
        width: 100%;
        flex-direction: column-reverse;
    }

    .job-post_analytics{
        flex: 1;
        width: 80%;
        margin-bottom: 15px;
        align-self: flex-start;
        padding-right: 30px;
        
    }

    .job-post_description{
        flex: 1;
        width: 80%;
        align-self: flex-start;
        margin-left: 0;
    }

    .job-info{
        flex-direction: column;
    }

    .workenvironment, .industry, .area-of-focus{
        margin-bottom: 15px;
    }
}



@media screen and (max-width: 768px) {
    
    .description-subtitle{
        font-size: 14px;
    }
 }