.message_before {
    border-radius: 20px 3px 20px 20px;
    display: block;
    padding: 7px 13px 7px 13px;
    margin-left: 40px;
}

.message_after {
    border-radius: 3px 20px 20px 20px;
    display: block;
    padding: 7px 13px 7px 13px;
    position: relative;
    left: 0;
    margin-right: 40px;
}
